import React, { useState, useEffect } from "react";
import tracks from "../tracks";
import { usePlayback } from "../PlaybackContext";
import { PlayIcon, SpeakerWaveIcon } from "@heroicons/react/24/solid";

const TracksGrid = () => {
  const { isPlaying, setIsPlaying, currentTrackId, setCurrentTrackId } =
    usePlayback();

  const [randomizedTracks, setRandomizedTracks] = useState([]);

  const shuffleArray = (array) => {
    const newArr = [...array];
    for (let i = newArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
    }
    return newArr;
  };

  useEffect(() => {
    setRandomizedTracks(shuffleArray(tracks));
  }, []);

  const handleSongSelect = (song) => {
    if (song.id !== currentTrackId) {
      setCurrentTrackId(song.id);
      setIsPlaying(true);
    } else {
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
      {randomizedTracks.map((song) => (
        <div
          key={song.id}
          className="relative rounded-lg overflow-hidden"
          onClick={() => handleSongSelect(song)}
        >
          <div className="relative w-full h-24 sm:h-32 hover:brightness-75 cursor-pointer">
            <img
              src={song.image}
              alt={song.title}
              className="w-full h-full object-unset"
            />
            {currentTrackId === song.id ? (
              <div className="absolute inset-0 flex items-center justify-center">
                {isPlaying ? (
                  <SpeakerWaveIcon className="h-8 w-8 text-white" />
                ) : (
                  <PlayIcon className="h-8 w-8 text-white" />
                )}
              </div>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center justify-center sm:hidden">
                  <PlayIcon className="h-8 w-8 text-white" />
                </div>
                <div className="absolute inset-0 items-center justify-center hidden sm:flex opacity-0 hover:opacity-100">
                  <PlayIcon className="h-8 w-8 text-white" />
                </div>
              </>
            )}
          </div>
          <div>
            <h3 className="track-title mt-2">{song.title}</h3>
            <p className="track-description">{song.artist}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TracksGrid;
