const tracks = [
  {
    id: 1,
    title: "Control Substance",
    artist: "GiGi & NoFace",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track1.mp3",
    image: "images/track1.jpg",
  },
  {
    id: 2,
    title: "Cosmic Ritual (Vertikka Remix)",
    artist: "Emov",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track2.mp3",
    image: "images/track2.jpg",
  },
  {
    id: 3,
    title: "Cosmic Traveller",
    artist: "Burn in Noise & Webra",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track3.mp3",
    image: "images/track3.webp",
  },
  {
    id: 4,
    title: "Cycles (Sator Arpedo Remix)",
    artist: "Imagine Mars",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track4.mp3",
    image: "images/track4.jpg",
  },
  {
    id: 5,
    title: "Deep Breath",
    artist: "Chronosphere & Hyperscript",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track5.mp3",
    image: "images/track5.jpg",
  },
  {
    id: 6,
    title: "Loosing My Mind",
    artist: "Chronosphere & Hyperscript",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track6.mp3",
    image: "images/track6.jpg",
  },
  {
    id: 7,
    title: "Digital Drops (Reborn vs X-Side)",
    artist: "Reborn & X-Side",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track7.mp3",
    image: "images/track7.jpg",
  },
  {
    id: 8,
    title: "Double Trip",
    artist: "Emov & Chronosphere",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track8.mp3",
    image: "images/track8.jpg",
  },
  {
    id: 9,
    title: "G.O.T (Outsiders Remix)",
    artist: "Alturism & Burn in Noise",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track9.mp3",
    image: "images/track9.jpg",
  },
  {
    id: 10,
    title: "Goa on My Mind (Gms Remix)",
    artist: "Jimbo & Volcano On Mars",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track10.mp3",
    image: "images/track10.jpg",
  },
  {
    id: 11,
    title: "Howling at the Moon",
    artist: "Undefined Behavior, Burn in Noise & Ajja",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track11.mp3",
    image: "images/track11.jpg",
  },
  {
    id: 12,
    title: "Labyrinth of Creations",
    artist: "Chronosphere & Yner",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track12.mp3",
    image: "images/track12.jpg",
  },
  {
    id: 13,
    title: "Mad Noise",
    artist: "GMS & Burn in Noise",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track13.mp3",
    image: "images/track13.webp",
  },
  {
    id: 14,
    title: "Mantra (Volcano On Mars Remix)",
    artist: "Electric Universe",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track14.mp3",
    image: "images/track14.jpg",
  },
  {
    id: 15,
    title: "Lanikea",
    artist: "Hinap",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track15.mp3",
    image: "images/track15.jpg",
  },
  {
    id: 16,
    title: "Mysterious Forces",
    artist: "Laughing Buddha",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track16.mp3",
    image: "images/track16.jpg",
  },
  {
    id: 17,
    title: "Our Future",
    artist: "Volcano On Mars",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track17.mp3",
    image: "images/track17.jpg",
  },
  {
    id: 18,
    title: "Our Thought (Outsiders the Universe Remix)",
    artist: "Volcano",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track18.mp3",
    image: "images/track18.webp",
  },
  {
    id: 19,
    title: "Pandoras Box",
    artist: "NoFace",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track19.mp3",
    image: "images/track19.jpg",
  },
  {
    id: 20,
    title: "Universal Generator",
    artist: "Spectra Sonics & NoFace",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track20.mp3",
    image: "images/track20.jpg",
  },
  {
    id: 21,
    title: "Planet Gone Mad",
    artist: "Mad Tribe",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track21.mp3",
    image: "images/track21.jpg",
  },
  {
    id: 22,
    title: "Real World",
    artist: "Tristan & Volcano On Mars",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track22.mp3",
    image: "images/track22.jpg",
  },
  {
    id: 23,
    title: "Rocket Power",
    artist: "Mad Tribe",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track23.mp3",
    image: "images/track23.jpg",
  },
  {
    id: 24,
    title: "Shamanic (NoFace Remix)",
    artist: "Alturism & Burn in Noise",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track24.mp3",
    image: "images/track24.jpg",
  },
  {
    id: 25,
    title: "Spacesnake",
    artist: "Braincell",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track25.mp3",
    image: "images/track25.jpg",
  },
  {
    id: 26,
    title: "Story of Ganja",
    artist: "Volcano & Imagine Mars",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track26.mp3",
    image: "images/track26.jpg",
  },
  {
    id: 27,
    title: "Talking Machines",
    artist: "Braincell",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track27.mp3",
    image: "images/track27.jpg",
  },
  {
    id: 28,
    title: "Teleportation",
    artist: "Braincell",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track28.mp3",
    image: "images/track28.jpg",
  },
  {
    id: 29,
    title: "The Invisibles",
    artist: "Xerox & Volcano On Mars",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track29.mp3",
    image: "images/track29.jpg",
  },
  {
    id: 30,
    title: "Think Nothing (Jumpstreet Remix)",
    artist: "James West",
    src: "https://eyeburner.com/wp-content/themes/shoper/tracks/track30.mp3",
    image: "images/track30.jpg",
  },
];

export default tracks;
