import {
  BackwardIcon,
  ForwardIcon,
  PauseIcon,
  PlayIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/solid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import { usePlayback } from "../PlaybackContext";
import "./PlayReact.css";

const PlayReact = ({ tracks }) => {
  const { isPlaying, setIsPlaying, currentTrackId, setCurrentTrackId } =
    usePlayback();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [progressBarPosition, setProgressBarPosition] = useState(null);
  const [savedVolume, setSavedVolume] = useState(1);
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [isWidth480OrLess, setIsWidth480OrLess] = useState(false);

  const audioRef = useRef(null);

  const currentTrack = tracks.find((track) => track.id === currentTrackId);
  const currentTrackIndex = tracks.indexOf(currentTrack);

  useEffect(() => {
    if (isPlaying) {
      if (audioRef.current.paused && audioRef.current.readyState)
        audioRef.current.play();
    } else {
      if (!audioRef.current.paused && audioRef.current.readyState)
        audioRef.current.pause();
    }
  }, [isPlaying]);

  const previousTrack = useCallback(async () => {
    const audio = audioRef.current;
    const previousTrackIndex =
      currentTrackIndex !== -1
        ? (currentTrackIndex - 1 + tracks.length) % tracks.length
        : 0;
    setCurrentTrackId(tracks[previousTrackIndex].id);

    const handleCanPlayThrough = async () => {
      try {
        await audio.play();
        audio.removeEventListener("canplaythrough", handleCanPlayThrough);
      } catch (error) {
        console.error("Playback error:", error);
      }
    };

    audio.addEventListener("canplaythrough", handleCanPlayThrough);
  }, [currentTrackIndex, setCurrentTrackId, tracks]);

  const nextTrack = useCallback(async () => {
    const audio = audioRef.current;
    const nextTrackIndex = (currentTrackIndex + 1) % tracks.length;
    setCurrentTrackId(tracks[nextTrackIndex].id);

    const handleCanPlayThrough = async () => {
      try {
        await audio.play();
        audio.removeEventListener("canplaythrough", handleCanPlayThrough);
      } catch (error) {
        console.error("Playback error:", error);
      }
    };

    audio.addEventListener("canplaythrough", handleCanPlayThrough);
  }, [currentTrackIndex, setCurrentTrackId, tracks]);

  const togglePlay = useCallback(() => {
    setIsPlaying(!isPlaying);
  }, [setIsPlaying, isPlaying]);

  const toggleMute = useCallback(() => {
    setIsMuted((prev) => {
      if (prev) {
        setVolume(savedVolume);
        audioRef.current.volume = savedVolume;
      } else {
        setSavedVolume(volume);
        setVolume(0);
        audioRef.current.volume = 0;
      }
      return !prev;
    });
  }, [volume, savedVolume]);

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  useEffect(() => {
    const handleMediaSessionAction = (event) => {
      switch (event.action) {
        case "play":
          setIsPlaying(true);
          break;
        case "pause":
          setIsPlaying(false);
          break;
        case "seekbackward":
          audioRef.current.currentTime -= 10;
          break;
        case "seekforward":
          audioRef.current.currentTime += 10;
          break;
        case "stop":
          setIsPlaying(false);
          audioRef.current.currentTime = 0;
          break;
        case "previoustrack":
          previousTrack();
          break;
        case "nexttrack":
          nextTrack();
          break;
        default:
          break;
      }
    };

    const actions = [
      "play",
      "pause",
      "seekbackward",
      "seekforward",
      "stop",
      "previoustrack",
      "nexttrack",
    ];

    if ("mediaSession" in navigator)
      actions.forEach((action) =>
        navigator.mediaSession.setActionHandler(
          action,
          handleMediaSessionAction
        )
      );
  }, [toggleMute, nextTrack, previousTrack, setIsPlaying]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case " ":
          event.preventDefault();
          togglePlay();
          break;
        case "k":
        case "K":
          togglePlay();
          break;
        case "l":
        case "L":
          audioRef.current.currentTime += 10;
          break;
        case "j":
        case "J":
          audioRef.current.currentTime -= 10;
          break;
        case "m":
        case "M":
          toggleMute();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [toggleMute, togglePlay]);

  useEffect(() => {
    const checkWindowWidth = () => {
      setIsWidth480OrLess(window.innerWidth <= 480);
    };

    checkWindowWidth();

    window.addEventListener("resize", checkWindowWidth);

    return () => {
      window.removeEventListener("resize", checkWindowWidth);
    };
  }, []);

  const changeVolume = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
    setIsMuted(newVolume === "0");
  };

  const handleProgressBarMouseMove = (event) => {
    const progressBar = event.target;
    const rect = progressBar.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const position = offsetX / progressBar.offsetWidth;
    setProgressBarPosition(position);
  };

  const handleProgressBarMouseLeave = () => {
    setProgressBarPosition(null);
  };

  const handleProgressBarClick = () => {
    audioRef.current.currentTime = progressBarPosition * duration;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div
      className="audio-player slide-in"
      onMouseLeave={() => setShowVolumeSlider(false)}
    >
      <div className="progress-bar-container">
        <input
          type="range"
          min="0"
          max="1"
          step="0.001"
          value={currentTime / duration || 0}
          onChange={(event) => {
            if (
              isFinite(event.target.valueAsNumber) &&
              isFinite(audioRef.current.duration)
            ) {
              audioRef.current.currentTime =
                event.target.valueAsNumber * audioRef.current.duration;
            }
          }}
          className="progress-bar"
          style={{
            "--progress-percentage": `${(currentTime / duration || 0) * 100}%`,
          }}
          onMouseMove={handleProgressBarMouseMove}
          onMouseLeave={handleProgressBarMouseLeave}
          onClick={handleProgressBarClick}
        />
        {progressBarPosition !== null && (
          <span
            className="progress-time"
            style={{
              position: "absolute",
              bottom: "80px",
              left: `${progressBarPosition * 100}%`,
              transform: "translateX(-50%)",
            }}
          >
            {formatTime(progressBarPosition * duration)}
          </span>
        )}
      </div>
      <div className="left-controls">
        <BackwardIcon
          onClick={previousTrack}
          className="cursor-pointer h-6 w-6 mr-5 previous-track"
        />
        {isPlaying ? (
          <PauseIcon onClick={togglePlay} className="cursor-pointer h-8 w-8" />
        ) : (
          <PlayIcon onClick={togglePlay} className="cursor-pointer h-8 w-8" />
        )}
        <ForwardIcon
          onClick={nextTrack}
          className="cursor-pointer h-6 w-6 ml-5 mr-5"
        />
        <div className="timer text-xs tiny-color select-text">
          {currentTime ? formatTime(currentTime) : "0:00"} /{" "}
          {duration ? formatTime(duration) : "0:00"}
        </div>
      </div>
      <div className="center-controls">
        <img src={currentTrack.image} alt={currentTrack.title} />
        <div className="track-info ml-2">
          {currentTrack.title.length > 20 && isWidth480OrLess ? (
            <Marquee delay="2" speed="20">
              <p className="track-title">{currentTrack.title}</p>
            </Marquee>
          ) : (
            <p className="track-title">{currentTrack.title}</p>
          )}
          <p className="track-description">{currentTrack.artist}</p>
        </div>
      </div>
      <div className="right-controls">
        <div
          className="volume-container"
          onMouseEnter={() => setShowVolumeSlider(true)}
        >
          {!isMuted ? (
            <SpeakerWaveIcon onClick={toggleMute} className="h-6 w-6" />
          ) : (
            <SpeakerXMarkIcon onClick={toggleMute} className="h-6 w-6" />
          )}
          <div
            className={`volume-slider-container ${
              showVolumeSlider ? "visible" : ""
            }`}
          >
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={changeVolume}
              className="volume-slider"
            />
          </div>
        </div>
      </div>
      <div className="mobile-controls">
        <div className="control-wrapper">
          {isPlaying ? (
            <PauseIcon
              onClick={togglePlay}
              className="cursor-pointer h-5 w-5"
            />
          ) : (
            <PlayIcon onClick={togglePlay} className="cursor-pointer h-5 w-5" />
          )}
        </div>
        <div className="control-wrapper">
          <ForwardIcon onClick={nextTrack} className="cursor-pointer h-5 w-5" />
        </div>
      </div>
      <div className="progress-bar-container-mobile">
        <input
          type="range"
          min="0"
          max="1"
          step="0.001"
          value={currentTime / duration || 0}
          className="progress-bar-mobile"
          readOnly
          style={{
            "--progress-percentage": `${(currentTime / duration || 0) * 100}%`,
          }}
        />
      </div>
      <audio
        ref={audioRef}
        src={currentTrack.src}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        onEnded={() => nextTrack()}
        onCanPlayThrough={() => audioRef.current.play()}
        onTimeUpdate={handleTimeUpdate}
        volume={volume}
      />
    </div>
  );
};

export default PlayReact;
