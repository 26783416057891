import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { PlaybackProvider } from "./PlaybackContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PlaybackProvider>
    <App />
  </PlaybackProvider>
);
