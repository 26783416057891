const artists = [
  {
    name: "Braincell",
    image: "images/braincell.webp",
  },
  {
    name: "Altruism",
    image: "images/altruism.jpeg",
  },
  {
    name: "Burn in Noise",
    image: "images/burn_in_noise.jpeg",
  },
  {
    name: "Volcano",
    image: "images/volcano.webp",
  },
  {
    name: "SpectraSonics",
    image: "images/spectrasonics.jpeg",
  },
  {
    name: "NoFace",
    image: "images/noface.jpeg",
  },
  {
    name: "Chronosphere",
    image: "images/chronosphere.jpeg",
  },
  {
    name: "Imagine Mars",
    image: "images/imagine_mars.webp",
  },
  {
    name: "Mad Max",
    image: "images/mad_max.jpeg",
  },
  {
    name: "Space Tribe",
    image: "images/space_tribe.jpeg",
  },
];

export default artists;
