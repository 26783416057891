import React from "react";
import "./Navigation.css";
import "./Navigation.css";

const Navigation = () => {
  return (
    <nav className="flex items-center justify-center h-16 bg-black text-white border-b border-white border-opacity-100">
      <h1 className="text-3xl psychedelic-title">SYNOPSIS</h1>
    </nav>
  );
};

export default Navigation;
