import React from "react";
import Navigation from "./Navigation";
import TracksGrid from "./TracksGrid";
import PlayReact from "./PlayReact";
import { usePlayback } from "../PlaybackContext";
import tracks from "../tracks";
import ArtistsGrid from "./ArtistsGrid";
import "./MusicHomepage.css";

const MusicHomepage = () => {
  const { currentTrackId } = usePlayback();
  return (
    <div className="h-screen flex flex-col">
      <div className="z-40">
        <Navigation />
      </div>
      <div className="relative flex-grow overflow-y-auto tracks-grid-wrapper z-20">
        <div className="relative container mx-auto px-4 tracks-container">
          <h2 className="text-white text-2xl mb-4 heading">Music</h2>
          <TracksGrid />
          <h2 className="text-white text-2xl mb-4 mt-4 heading">Artists</h2>
          <ArtistsGrid />
        </div>
      </div>
      {currentTrackId && (
        <div className="fixed bottom-0 left-0 right-0 z-30">
          <PlayReact tracks={tracks} />
        </div>
      )}
    </div>
  );
};

export default MusicHomepage;
