import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import artists from "../artists";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import "./ArtistsGrid.css";

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
  ],
};

const ArtistsGrid = () => {
  const sliderRef = useRef(null);

  return (
    <div className="artists-grid">
      <Slider ref={sliderRef} {...sliderSettings}>
        {artists.map((artist, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center relative rounded-full cursor-cursor force"
          >
            <div className="relative w-24 h-24 sm:w-32 sm:h-32 flex items-center justify-center">
              <img
                src={artist.image}
                alt={artist.name}
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <h3 className="text-white artist-name mt-4 text-center">
              {artist.name}
            </h3>
          </div>
        ))}
      </Slider>
      <div className="flex flex-row items-center justify-center mt-2">
        <ArrowLongLeftIcon
          onClick={() => sliderRef.current?.slickPrev()}
          className="h-6 w-6 text-gray-500 mr-2 cursor-pointer hover:text-white"
        />
        <ArrowLongRightIcon
          onClick={() => sliderRef.current?.slickNext()}
          className="h-6 w-6 text-gray-500 ml-2 cursor-pointer hover:text-white"
        />
      </div>
    </div>
  );
};

export default ArtistsGrid;
